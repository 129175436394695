import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './utils/axios';

import { i18n } from './i18n/i18n.js'

import "@/style/defaule.scss"
// import "@/style/mycss.scss"

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

// 图片预览
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

// 按需引入ui库
import { Swiper, SwiperItem, Button, Navbar, TextArea, Tabbar, TabbarItem, Tabs, TabPane, SearchBar, Toast, Category, CategoryPane, Empty, InfiniteLoading, Sku, OverLay, ActionSheet, Popup, Dialog, AddressList, Address, Elevator, InputNumber, Price, Cell, CellGroup, BackTop, Input, Icon, Tag, Layout, Row, Col, ConfigProvider, Image, Ellipsis, Checkbox, CheckboxGroup, Popover, Pagination, Form, FormItem, ImagePreview, Menu, MenuItem, Radio, RadioGroup } from "@nutui/nutui"

import "@nutui/nutui/dist/style.css";

import global from "@/utils/global.js"


const app = createApp(App)
app.component('Datepicker', Datepicker);

app
  .use(Radio)
  .use(RadioGroup)
  .use(Menu)
  .use(MenuItem)
  .use(Form)
  .use(FormItem)
  .use(Pagination)
  .use(Popover)
  .use(Ellipsis)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Tabbar)
  .use(Image)
  .use(ConfigProvider)
  .use(Layout)
  .use(Row)
  .use(Col)
  .use(Tag)
  .use(TabbarItem)
  .use(TextArea)
  .use(Elevator)
  .use(AddressList)
  .use(Address)
  .use(Button)
  .use(Tabs)
  .use(TabPane)
  .use(Navbar)
  .use(SearchBar)
  .use(ActionSheet)
  .use(Toast)
  .use(Category)
  .use(CategoryPane)
  .use(Dialog)
  .use(Swiper)
  .use(SwiperItem)
  .use(Empty)
  .use(InfiniteLoading)
  .use(CellGroup)
  .use(Sku)
  .use(Popup)
  .use(Input)
  .use(InputNumber)
  .use(Price)
  .use(Cell)
  .use(BackTop)
  .use(OverLay)
  .use(ImagePreview)
  .use(Icon)
  .use(store)
  .use(global)
  .use(VueViewer, {
    defaultOptions: {
      navbar: false,
      title: false,
      toolbar: false
    }
  })
  .use(i18n)
  .use(router)
  .mount('#app')
app.config.globalProperties.$axios = axios;

