
/**
 * 定义项目接口api
 */
const API: any = {
  // 获取省市区地址
  getAddress: '/static/china_app.json',
  // 注册
  register: '/app/api/agent/register/',
  // 登录
  login: '/app/api/agent/login/',
  // 获取商品分类
  getGoodsCate: '/app/api/goods/cate/',
  // 获取商品列表
  getGoods: '/app/api/goods/',
  // 商品sku
  getGoodsSku: '/app/api/goods/sku/',
  // 新闻列表
  getArticle: '/app/api/article/',
  // 新闻详情
  getArticleInfo: '/app/api/article/',
  // 新增地址、地址列表
  addAddress: '/app/api/agent/address/',
  // 商品列表（get），加入购物车（post）,修改数量（put），删除（delete）
  handleCart: '/app/api/goods/cart/',
  // 下单
  order: '/app/api/order/',
  // 订单列表接口
  orderList: '/app/api/order/',
  //订单详情
  orderInfo: '/app/api/order/info/',
  // 商品搜索功能
  search: '/app/api/goods/search/',
  // 购物车概览接口
  summary: '/app/api/goods/cart/summary/',
  // 清空购物单
  removeAll: '/app/api/goods/cart/remove_all/',
  //收件方式
  receiptMethod: '/app/api/supplier/delivery/service/',
  //运费规格
  shippingSpecifications: '/app/api/supplier/delivery/fee_by_weight/',
  //我的信息接口
  userInfo: '/app/api/agent/info/',
  //修改登录密码
  changePsw: '/app/api/agent/password/',
  //搜索代理商接口（无分页）
  valetSearch: '/app/api/agent/search/',
  //曾代客下单的代理商列表接口（有分页）
  valetList: "/app/api/agent/help_list/",
  //选择代理商身份使用接口
  selectValet: "/app/api/agent/help_now/",
  //退出登录
  logout: "/app/api/agent/logout/"
}

export default API